<template>
  <div class="q-mt-md">
    <label class="text-grey-9 text-bold q-mt-sm">{{ $t('label.push.schedule') }}</label>
    <q-list dense style="width: 70%">
      <q-item>
        <q-item-section>
          <q-select
            outlined
            dense
            option-value="id"
            option-label="title"
            v-model="value.schedule_type"
            :options="options"
            style="width: 93%"
            emit-value
            map-options
            :label="$t('label.push.schedule_type')"
            :rules="[(val) => (val && val.length > 0) || requiredRule.scheduleType]"
          />
        </q-item-section>
        <q-item-section v-if="value.schedule_type === 'one_time'">
          <q-input
            outlined
            dense
            v-model="value.date"
            type="date"
            :rules="[(val) => (val && val.length > 0) || requiredRule.date, isValidDate]"
            style="position: relative; width: 93%"
            ref="inputRefDate"
          />
        </q-item-section>

        <q-item-section v-if="value.schedule_type === 'multi_time'">
          <q-select
            outlined
            dense
            option-value="id"
            option-label="title"
            v-model="value.schedule_by_type"
            :options="getScheduleType(value.schedule_type)"
            style="width: 93%"
            emit-value
            map-options
            :label="$t('label.push.type')"
            :rules="[(val) => (val && val.length > 0) || requiredRule.type]"
          />
        </q-item-section>

        <q-item-section v-if="value.schedule_type === 'multi_time' && value.schedule_by_type === 'by_week'">
          <q-select
            outlined
            dense
            option-value="id"
            option-label="title"
            v-model="value.day_of_week"
            :options="dateOfWeekOptions"
            style="width: 93%"
            emit-value
            map-options
            :label="$t('label.push.day_of_week')"
            :rules="['']"
          />
        </q-item-section>

        <q-item-section v-if="value.schedule_type === 'multi_time' && value.schedule_by_type === 'by_delay_date'">
          <q-input
            v-model="value.delay_day"
            dense
            outlined
            type="number"
            :label="$t('label.push.delay_days')"
            class="text-h6"
            style="width: 93%"
            :rules="[(val) => (val && val.length > 0) || requiredRule.delayDays]"
          />
        </q-item-section>

        <q-item-section v-if="value.schedule_type === 'multi_time' && value.schedule_by_type === 'by_month'">
          <q-select
            outlined
            dense
            v-model="value.day_of_month"
            :options="dayofMonthOptions"
            style="width: 93%"
            :label="$t('label.push.day_of_month')"
            :rules="['']"
          />
        </q-item-section>

        <q-item-section class="q-ml-sm">
          <q-input
            dense
            outlined
            class="text-h6"
            v-model="value.time"
            type="time"
            :rules="[(val) => (val && val.length > 0) || requiredRule.time, isValidDate]"
            style="position: relative"
            ref="inputRefTime"
          />
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { ISchedule } from '@/utils/types'
import PushCampaignMixin from '../mixins/PushCampaignMixin.vue'
import { SCHEDULE_BY_TYPE, SCHEDULE_TYPE } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class ScheduleSetting extends mixins(PushCampaignMixin) {
  @Prop({
    default: {
      schedule_type: '',
      schedule_by_type: '',
      delay_day: 0,
      day_of_week: 0,
      day_of_month: 1,
      date: '',
      time: '',
    },
  })
  modelValue!: ISchedule

  get requiredRule() {
    const requiredRule = {
      scheduleType: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.push.schedule_type'),
      }),
      type: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.push.type'),
      }),
      date: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.push.date'),
      }),
      delayDays: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.push.delay_days'),
      }),
      time: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.push.time'),
      }),
    }
    return requiredRule
  }

  get value() {
    return this.modelValue
  }

  set value(value: ISchedule) {
    this.$emit('update:modelValue', value)
  }

  get options() {
    const options = [
      {
        id: SCHEDULE_TYPE.ONE_TIME,
        title: this.$t('label.push.one_time'),
        data: [],
      },
      {
        id: SCHEDULE_TYPE.MULTI_TIME,
        title: this.$t('label.push.multi_time'),
        data: [
          {
            id: SCHEDULE_BY_TYPE.BY_DELAY_DATE,
            title: this.$t('label.push.by_delay_date'),
          },
          {
            id: SCHEDULE_BY_TYPE.BY_WEEK,
            title: this.$t('label.push.by_week'),
          },
          {
            id: SCHEDULE_BY_TYPE.BY_MONTH,
            title: this.$t('label.push.by_month'),
          },
        ],
      },
    ]
    return options
  }

  get dateOfWeekOptions() {
    const dateOfWeek = [
      {
        id: 6,
        title: this.$t('label.push.sunday'),
      },
      {
        id: 0,
        title: this.$t('label.push.monday'),
      },
      {
        id: 1,
        title: this.$t('label.push.tuesday'),
      },
      {
        id: 2,
        title: this.$t('label.push.wednesday'),
      },
      {
        id: 3,
        title: this.$t('label.push.thursday'),
      },
      {
        id: 4,
        title: this.$t('label.push.friday'),
      },
      {
        id: 5,
        title: this.$t('label.push.saturday'),
      },
    ]
    return dateOfWeek
  }

  get dayofMonthOptions() {
    const dayOfMonth: number[] = []
    for (let j = 1; j <= 31; j++) {
      dayOfMonth.push(j)
    }
    return dayOfMonth
  }

  @Watch('value.schedule_type')
  scheduleChange() {
    if (this.value.schedule_type === SCHEDULE_TYPE.ONE_TIME) {
      this.value.day_of_week = 0
      this.value.delay_day = 0
      this.value.day_of_month = 1
      this.value.schedule_by_type = ''
    } else {
      this.value.date = ''
    }
  }

  getScheduleType(schedule_type: string) {
    return this.options.find((item) => item.id === schedule_type)?.data || []
  }

  isValidDate() {
    if (this.$refs.inputRefDate) {
      this.$refs.inputRefDate.resetValidation()
    }
    if (this.$refs.inputRefTime) {
      this.$refs.inputRefTime.resetValidation()
    }

    if (this.value.date !== '' && this.value.time !== '') {
      const datetime = `${this.value.date} ${this.value.time}`
      const selected = new Date(datetime)
      const current = new Date()
      // Add 10 minutes to the current date and time
      const tenMinutesFromNow = new Date(current.getTime() + 9 * 60 * 1000)
      if (selected < tenMinutesFromNow) {
        return false || this.$t('validate.date_time_is_greater_than_the_current_date_time')
      }
    }
  }
}
</script>
