import { render } from "./AudienceOrNotSetting.vue?vue&type=template&id=ad4fab14&scoped=true"
import script from "./AudienceOrNotSetting.vue?vue&type=script&lang=ts"
export * from "./AudienceOrNotSetting.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-ad4fab14"

export default script
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToolbar,QToolbarTitle,QTable,QTr,QTd,QItem,QBtn,QList,QItemSection,QItemLabel});qInstall(script, 'directives', {Ripple});
